export const drawerWidth = 450;
export const sideMenuWidth = 230;

export const smsCodeTimeout = 100; //100 seconds (must be the same as the cloud function timeout)
export const codeLength = 5; //6 digits (must be the same as the cloud function)
export const apiRequestTimeout = 30000; // in milliseconds
export const apiRequestRetry = 15; // in seconds

export const TOGGLE_SIDE_MENU = "TOGGLE_SIDE_MENU";
export const SWITCH_LOCALE = "SWITCH_LOCALE";
export const SWITCH_APP_STATE = "SWITCH_APP_STATE";
export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE";

export const GET_APP_INFO_REQUEST = "GET_APP_INFO_REQUEST";
export const GET_APP_INFO_SUCCESS = "GET_APP_INFO_SUCCESS";
export const GET_APP_INFO_ERROR = "GET_APP_INFO_ERROR";

export const ADD_INSTALLATION_REQUEST = "ADD_INSTALLATION_REQUEST";
export const ADD_INSTALLATION_SUCCESS = "ADD_INSTALLATION_SUCCESS";
export const ADD_INSTALLATION_ERROR = "ADD_INSTALLATION_ERROR";

export const UPDATE_DEVICE_ID = "UPDATE_DEVICE_ID";
export const SKIP_APP_UPDATE = "SKIP_APP_UPDATE";
export const RESET_APP_USAGE_REQUEST = "RESET_APP_USAGE_REQUEST";
export const RESET_APP_USAGE_SUCCESS = "RESET_APP_USAGE_SUCCESS";
export const RESET_APP_USAGE_ERROR = "RESET_APP_USAGE_ERROR";
export const UPDATE_APP_USAGE_START = "UPDATE_APP_USAGE_START";
export const UPDATE_APP_USAGE_END = "UPDATE_APP_USAGE_END";

export const PURGE_STORE_REQUEST = "PURGE_STORE_REQUEST";
export const PURGE_STORE_SUCCESS = "PURGE_STORE_SUCCESS";
export const PURGE_STORE_ERROR = "PURGE_STORE_ERROR";

export const LOGIN_PASS_REQUEST = "LOGIN_PASS_REQUEST";
export const LOGIN_PASS_AZURE_REQUEST = "LOGIN_PASS_AZURE_REQUEST";
export const LOGIN_PASS_SUCCESS = "LOGIN_PASS_SUCCESS";
export const LOGIN_PASS_ERROR = "LOGIN_PASS_ERROR";
export const ADD_STAFF_REQUEST_AZURE = "ADD_STAFF_REQUEST_AZURE";
export const ADD_STAFF_SUCCESS_AZURE = "ADD_STAFF_SUCCESS_AZURE";
export const ADD_STAFF_ERROR_AZURE = "ADD_STAFF_ERROR_AZURE";

export const LOGIN_SUPER_FOCAL_POINT_REQUEST =
  "LOGIN_SUPER_FOCAL_POINT_REQUEST";
export const LOGIN_SUPER_FOCAL_POINT_SUCCESS =
  "LOGIN_SUPER_FOCAL_POINT_SUCCESS";
export const LOGIN_SUPER_FOCAL_POINT_ERROR = "LOGIN_SUPER_FOCAL_POINT_ERROR";

export const LOGIN_SUPER_CONTRACT_FOCAL_POINT_REQUEST =
  "LOGIN_SUPER_CONTRACT_FOCAL_POINT_REQUEST";
export const LOGIN_SUPER_CONTRACT_FOCAL_POINT_SUCCESS =
  "LOGIN_SUPER_CONTRACT_FOCAL_POINT_SUCCESS";
export const LOGIN_SUPER_CONTRACT_FOCAL_POINT_ERROR =
  "LOGIN_SUPER_CONTRACT_FOCAL_POINT_ERROR";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

export const LOGIN_SMS_CODE_REQUEST = "LOGIN_SMS_CODE_REQUEST";
export const LOGIN_SMS_CODE_REQUEST_AZURE = "LOGIN_SMS_CODE_REQUEST_AZURE";
export const LOGIN_SMS_CODE_SUCCESS = "LOGIN_SMS_CODE_SUCCESS";
export const LOGIN_SMS_CODE_ERROR = "LOGIN_SMS_CODE_ERROR";
export const LOGIN_SMS_TIMEOUT = "LOGIN_SMS_TIMEOUT";
export const LOGIN_SMS_CANCEL = "LOGIN_SMS_CANCEL";
export const LOGIN_SMS_REQUEST = "LOGIN_SMS_REQUEST";
export const LOGIN_SMS_ERROR = "LOGIN_SMS_ERROR";
export const LOGIN_SMS_SUCCESS = "LOGIN_SMS_SUCCESS";

export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";

export const CHANGE_LOCALE = "CHANGE_LOCALE";

export const IS_SESSION_VALID_REQUEST = "IS_SESSION_VALID_REQUEST";
export const IS_SESSION_VALID_SUCCESS = "IS_SESSION_VALID_SUCCESS";
export const IS_SESSION_VALID_ERROR = "IS_SESSION_VALID_ERROR";

export const IS_USER_STATUS_REQUEST = "IS_USER_STATUS_REQUEST";
export const IS_USER_STATUS_SUCCESS = "IS_USER_STATUS_SUCCESS";
export const IS_USER_STATUS_ERROR = "IS_USER_STATUS_ERROR";

export const ASK_FOR_OTP = "ASK_FOR_OTP";
export const ASK_FOR_OTP_FALSE = "ASK_FOR_OTP_FALSE";

export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const SERVER_DOWN = "SERVER_DOWN";
export const INCREASE_WATCH_TUTORIAL_COUNT = "INCREASE_WATCH_TUTORIAL_COUNT";

export const CONTACT_INFO_REQUEST = "CONTACT_INFO_REQUEST";
export const CONTACT_INFO_SUCCESS = "CONTACT_INFO_SUCCESS";
export const CONTACT_INFO_ERROR = "CONTACT_INFO_ERROR";

export const CHANGE_TABLE_VIEW = "CHANGE_TABLE_VIEW";

export const CHECK_APP_VERSION_REQUEST = "CHECK_APP_VERSION_REQUEST";
export const CHECK_APP_VERSION_SUCCESS = "CHECK_APP_VERSION_SUCCESS";
export const CHECK_APP_VERSION_ERROR = "CHECK_APP_VERSION_ERROR";

export const VEHICLE_LOGIN_REQUEST = "VEHICLE_LOGIN_REQUEST";
export const VEHICLE_LOGIN_SUCCESS = "VEHICLE_LOGIN_SUCCESS";
export const VEHICLE_LOGIN_ERROR = "VEHICLE_LOGIN_ERROR";

export const BOOKING_TABLE_CHANGE = "BOOKING_TABLE_CHANGE";
export const RESET_LOGGED_IN_STAT = "RESET_LOGGED_IN_STAT";

export const GET_USER_TYPE_STAT_REQUEST = "GET_USER_TYPE_STAT_REQUEST";
export const GET_USER_TYPE_STAT_SUCCESS = "GET_USER_TYPE_STAT_SUCCESS";
export const GET_USER_TYPE_STAT_ERROR = "GET_USER_TYPE_STAT_ERROR";

export const GET_ROUTE_STAT_REQUEST = "GET_ROUTE_STAT_REQUEST";
export const GET_ROUTE_STAT_SUCCESS = "GET_ROUTE_STAT_SUCCESS";
export const GET_ROUTE_STAT_ERROR = "GET_ROUTE_STAT_ERROR";

export const GET_COST_STAT_REQUEST = "GET_COST_STAT_REQUEST";
export const GET_COST_STAT_SUCCESS = "GET_COST_STAT_SUCCESS";
export const GET_COST_STAT_ERROR = "GET_COST_STAT_ERROR";

export const GET_SEAT_UTILIZATION_STAT_REQUEST =
  "GET_SEAT_UTILIZATION_STAT_REQUEST";
export const GET_SEAT_UTILIZATION_STAT_SUCCESS =
  "GET_SEAT_UTILIZATION_STAT_SUCCESS";
export const GET_SEAT_UTILIZATION_STAT_ERROR =
  "GET_SEAT_UTILIZATION_STAT_ERROR";

export const GET_SEAT_UTIL_CHART_STAT_REQUEST =
  "GET_SEAT_UTIL_CHART_STAT_REQUEST";
export const GET_SEAT_UTIL_CHART_STAT_SUCCESS =
  "GET_SEAT_UTIL_CHART_STAT_SUCCESS";
export const GET_SEAT_UTIL_CHART_STAT_ERROR = "GET_SEAT_UTIL_CHART_STAT_ERROR";

export const GET_BOOKING_STAT_REQUEST = "GET_BOOKING_STAT_REQUEST";
export const GET_BOOKING_STAT_SUCCESS = "GET_BOOKING_STAT_SUCCESS";
export const GET_BOOKING_STAT_ERROR = "GET_BOOKING_STAT_ERROR";

export const PERSIST_DASHBOARD_FILTERS = "PERSIST_DASHBOARD_FILTERS";
